import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from "react-router";
import { ReactSession } from "react-client-session";

const useQuery = () => new URLSearchParams(useLocation().search);
export default function MatchesArbeitgeber(props) {

    const { locationChange, menuAppBarRef } = props;
    const [urlGenerated, setUrlGenerated] = useState(null);
    const location = useLocation();

    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        if (query.get('matchId') && query.get('jobAdId')) {
            setUrlGenerated(`${ENV.REACT_APP_AN_FRONTEND_URL}/matchesAG?matchId=${query.get('matchId')}&jobAdId=${query.get('jobAdId')}`)
        } else {
            setUrlGenerated(`${ENV.REACT_APP_AN_FRONTEND_URL}/matchesAG`)
        }

        if (ReactSession.get('user') != 'Arbeitgeber') {
            if (!ReactSession.get('user')) {
                menuAppBarRef.current.logNeeded();
                history.push('/');
            } else if (ReactSession.get('user') == 'Arbeitnehmer') {
                menuAppBarRef.current.logNeeded('Arbeitgeber');
                history.push('/login');
            }
        }
        document.title = 'Matches';
        locationChange(location);

    }, [])


    window.onmessage = async function (e) {
        if (typeof e.data == 'string' && e.data.includes('"matchId":"')) {
            const data = JSON.parse(e.data)
            history.push('/chat?matchId=' + data.matchId + '&jobAdId=' + data.jobAdId);
        } else if (typeof e.data == 'string' && e.data === 'routeToMatchkorb') {
            history.push('/matchkorb')
        }

    };

    return (
        <div style={{
            paddingTop: 64, height: '100%', width: '100%', background: 'white',
            border: 'none'
        }}>
            {urlGenerated && <iframe src={urlGenerated}
                     style={{
                         height: 'calc(100vh - 64px)', width: '100%',
                         border: 'none'
                     }}
                     id={'idIframe'}
                     sandbox={"allow-top-navigation allow-scripts allow-forms allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-downloads"}
            />}
        </div>
    );
}
